/** @jsx jsx */
import { jsx, Link as ThemeLink } from "theme-ui"
import { darken, lighten } from "@theme-ui/color"
import React, { useState, useCallback } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { Box, Flex, Grid } from "@theme-ui/components"
import SanitizedHTMLBodyCopy from "../components/sanitizedHTMLBodyCopy"
import { Collapse } from "react-collapse"
import { FaArrowDown } from "react-icons/fa"
import ShareIcons from "../components/shareIcons"
import FAQs from "../components/FAQs"
import EventCard from "../components/eventCard"
import HeaderBlockColor from "../components/headerBlockColor"
import PageGallery from "../components/pageGallery"

export const query = graphql`
  query SingleEventQuery($slug: String!) {
    strapiEvent(slug: { eq: $slug }) {
      strapiId
      Title
      Biog
      Date
      dateFormatted: Date(formatString: "MMMM DD, YYYY")
      Img {
        localFile {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
      Img_alt
      event_gallery {
        id
        img_alt
        img_src {
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
      Open
      Type
      Venue
      Description
      Time
      fa_q {
        Title
        description
        question_list {
          id
          answer
          question
        }
      }
      slug
    }

    allStrapiEvent(sort: { fields: Date }) {
      edges {
        node {
          strapiId
          Biog
          Date
          dateFormatted: Date(formatString: "MMMM DD, YYYY")
          Img {
            localFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
          Img_alt
          Open
          Title
          Type
          Venue
          strapiId
          Description
          Time
          blurb
          slug
        }
      }
    }
    site {
      siteMetadata {
        siteURL
      }
    }
  }
`

const EventPage = ({ data, location }) => {
  const [isButtonCollapseOpen, setIsButtonCollapseOpen] = useState(false)
  const onClick = useCallback(
    () => setIsButtonCollapseOpen(!isButtonCollapseOpen),
    [isButtonCollapseOpen]
  )
  //console.log("location", location)
  //single event
  const EventData = data.strapiEvent
  //future events
  let today = new Date()
  const dd = String(today.getDate()).padStart(2, "0")
  const mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
  const yyyy = today.getFullYear()
  today = yyyy + "-" + mm + "-" + dd
  //futureevents
  const futureEvents = data.allStrapiEvent.edges.filter(item => {
    //exclude this event
    if (EventData.strapiId !== item.node.strapiId) {
      return item.node.Date >= today
    }
  })
  //Is single event archive or future?
  let archive
  if (EventData.Date <= today) {
    archive = true
  } else {
    archive = false
  }
  //
  const eventType = EventData.Type.replace(/_|-/g, " ")
  //console.log("open", EventData.Open)
  const eventGallery = EventData.event_gallery
  console.log("eventGallery", eventGallery)
  return (
    <Layout headerType="">
      <SEO
        title={`${EventData.Title} | ${eventType}`}
        pathname={location.pathname}
      />
      <HeaderBlockColor />
      <Box
        sx={{
          mx: [0, 4],

          display: ["flex", "flex"],
          justifyContent: "flex-end",
          zIndex: 10,
          position: "relative",
        }}
      >
        <Link
          to="/whatson"
          sx={{
            bg: "accent",
            color: "white",
            py: 2,
            px: 3,
            textDecoration: "none",
            transition: "background 0.3s ease-in-out",
            "&:hover": {
              background: darken("accent", 0.1),
            },
          }}
        >
          Back to WhatsOn
        </Link>
      </Box>
      <Box
        sx={{ display: ["block", "flex"], mx: 4, mb: 5, position: "relative" }}
      >
        <Box sx={{ width: ["100%", "40%"], mt: "-60px", position: "relative" }}>
          <Img
            fluid={EventData.Img.localFile.childImageSharp.fluid}
            alt={EventData.Img_alt}
            sx={{
              height: "400px",
              width: ["100%", "90%", "70%"],
              position: "relative",
            }}
          />
          <ShareIcons
            type="Event"
            url={data.site.siteMetadata.siteURL + location.pathname}
          />
        </Box>
        <Box
          sx={{
            width: ["100%", "60%", "55%"],
            "& h1": {
              fontSize: [5, 6],
              fontWeight: 700,
              mb: [1],
            },
            "& h2": {
              color: "accent",
              my: 1,
            },
            "& .event-details": {
              //justifyContent: "space-between",
              "& div": {
                mr: 4,
                "& h4": {
                  color: "muted",
                  mb: 0,
                  fontWeight: "normal",
                },
                "& p": {
                  color: "accent",
                  mt: 1,
                  fontWeight: "bold",
                  fontSize: 3,
                },
              },
            },
          }}
        >
          <h1>{EventData.Title}</h1>
          <h2>{eventType}</h2>
          <Flex className="event-details" sx={{ display: ["block", "flex"] }}>
            <Box>
              <h4>Date</h4>
              <p>{EventData.dateFormatted}</p>
            </Box>
            {EventData.Time && (
              <Box>
                <h4>Time</h4>
                <p>{EventData.Time}</p>
              </Box>
            )}
            <Box>
              <h4>Venue</h4>
              <p>{EventData.Venue}</p>
            </Box>
          </Flex>
          <article>
            <Box
              sx={{
                fontSize: 4,
                color: "muted",
                "h1,h2,h3,h4,h5,h6": {
                  fontSize: 5,
                },
                "& .bodyCopy a": {
                  color: "accent",
                },
                "& .bodyCopy img": {
                  width: "100%",
                },
                "& .lead": {
                  fontSize: 5,
                  color: "text",
                },
                "& .small": {
                  fontSize: 3,
                },
              }}
            >
              <SanitizedHTMLBodyCopy content={EventData.Description} />
            </Box>
            {eventGallery.length > 0 && (
              <Box>
                <PageGallery gallery={eventGallery} />
              </Box>
            )}
            {EventData.Biog && (
              <Box
                sx={{
                  "& .bodyCopy": {
                    fontSize: 4,
                    color: "muted",
                    "h1,h2,h3,h4,h5,h6": {
                      fontSize: 5,
                    },
                    "& a": {
                      color: "accent",
                    },
                    "& img": {
                      width: "100%",
                    },
                  },
                  mb: 3,
                }}
              >
                <a
                  aria-controls="open biog"
                  aria-expanded={isButtonCollapseOpen}
                  onClick={onClick}
                  type="button"
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                    "& h3 svg.icon": {
                      fontSize: 2,
                      transform: "rotate(-90deg)",
                      transition: "all 0.2s ease-in-out",
                    },
                    "& h3 svg.icon.true": {
                      transform: "rotate(0deg)",
                    },
                  }}
                >
                  <h3>
                    Biog{" "}
                    <FaArrowDown className={`icon ${isButtonCollapseOpen}`} />
                  </h3>
                </a>
                <Collapse isOpened={isButtonCollapseOpen}>
                  <SanitizedHTMLBodyCopy content={EventData.Biog} />
                </Collapse>
              </Box>
            )}
            <div sx={{ mt: 3 }}>
              {!archive && (
                <>
                  {EventData.Open && (
                    <Link
                      to={`/app/event/${EventData.slug}`}
                      sx={{
                        variant: "buttons.primary",
                        marginRight: 3,
                      }}
                    >
                      Book A Place
                    </Link>
                  )}
                </>
              )}
              {EventData.fa_q && (
                <a
                  href="#faqs"
                  sx={{
                    variant: "buttons.primary",
                  }}
                >
                  FAQ's
                </a>
              )}
            </div>
          </article>
        </Box>
      </Box>
      <section sx={{ mx: 4, h1: { mb: [3, 3, 3] } }}>
        <h1>Upcoming Events</h1>
        <Grid gap={2} columns={[1, 3, 4]}>
          {futureEvents.map(item => {
            // console.log(node)
            return <EventCard item={item} />
          })}
        </Grid>
      </section>
      {EventData.fa_q && (
        <section sx={{ mx: [2, 3, 4] }} id="faqs">
          <FAQs content={EventData.fa_q} />
        </section>
      )}
    </Layout>
  )
}

export default EventPage
